.styles_listContainer__PQcz3 {
	display: grid;
	overflow-x: auto;
}
	@media screen and (max-width: 991.98px) {.styles_listContainer__PQcz3 {
		overflow-x: auto
}
	}
	@media screen and (max-width: 767.98px) {.styles_listContainer__PQcz3 {
		white-space: nowrap;
		width: calc(100% + 140px)
}
	}

.styles_list__ltOES {
	list-style: none;
	margin: 0;
	padding: 0;

	display: flex;
	gap: 32px;
	border-bottom: 1px solid #cccccc;
}

@media screen and (max-width: 1200px) {

.styles_list__ltOES {
		gap: 22px
}
	}

@media screen and (max-width: 767px) {

.styles_list__ltOES {
		margin-right: 140px
}
	}

.styles_item__yNh0G {
	position: relative;
	font-size: 14px;
	line-height: 1.5;
	padding-bottom: 4px;
	user-select: none;
}

.styles_item__yNh0G[data-clickable='true'] {
		cursor: pointer;
	}

.styles_item__yNh0G[data-active='true'] {
		color: #0061af;
	}

.styles_item__yNh0G[data-active='true']::after {
		display: block;
		content: '';
		position: absolute;
		width: 100%;
		height: 1px;
		bottom: -1px;
		background-color: #0061af;
	}

.styles_pageTitleWrap__E56WL {
	/*height: 74px; //36height + (24margin*2)*/
	/*height: 38px;*/

	display: flex;
	align-items: flex-start;
	padding: 0 0;
	margin-top: 16px;
	margin-bottom: 6px;

	position: sticky;
	top: 0;
	z-index: 2;
	background-color: var(--background-body);
	box-shadow: 0 -5px 5px 10px var(--background-body);
}

	.styles_pageTitleWrap__E56WL img:hover {
		cursor: pointer;
	}

	.styles_pageTitleWrap__E56WL .styles_title__0DOJU {
		font-weight: 600;
		font-size: 30px;
		line-height: 1.27;
		color: var(--primary-black);
		margin-bottom: 0;
	}

	@media screen and (max-width: 767px) {.styles_pageTitleWrap__E56WL {
		margin-bottom: 16px;
		margin-top: 16px;
		height: 32px;
		top: unset
}
	}

.styles_statusList__yQ4Sr {
	padding: 16px 0;
	position: sticky;
	top: 0;
	z-index: 2;
	background-color: var(--background-body);

	box-shadow: 0 10px 5px 10px var(--background-body);
}

@media screen and (max-width: 767px) {

.styles_statusList__yQ4Sr {
		padding: 16px 20px;
		box-shadow: 0 -10px 5px 10px var(--background-body)
}
	}

.styles_header__strgo {
	padding: 0 0;
	margin-bottom: 4px;

	position: sticky;
	top: 66px;
	z-index: 2;
	background-color: var(--background-body);
}

@media screen and (max-width: 767px) {

.styles_header__strgo {
		display: none
}
	}

.styles_list__gcCd1 {
	display: flex;
	height: 100%;
	flex-direction: column;

	list-style: none;
	margin: 0;
	padding: 0 0;
	gap: 4px;
}

.styles_list__gcCd1 li {
	}

@media screen and (max-width: 767px) {

.styles_list__gcCd1 {
		gap: 8px
}
	}

.styles_miniOrder__uQcQr {
	display: none;
}

@media screen and (max-width: 1365px) {

.styles_miniOrder__uQcQr[data-menu-minimize='false'] {
			display: flex
	}
		}

@media screen and (max-width: 1119px) {

.styles_miniOrder__uQcQr[data-menu-minimize='true'] {
			display: flex
	}
		}

.styles_archiveTitle__JWN8J {
	padding: 20px 32px;
	display: flex;
	align-items: center;
	gap: 32px;

	white-space: nowrap;
	color: var(--primary-grey);
}

@media (max-width: 767px) {

.styles_archiveButtonWrap__UB2BC {
		padding: 16px 16px 80px
}
	}

.styles_archiveButtonWrap__UB2BC {
	padding: 32px 32px 20px;

	display: flex;
	justify-content: center;
}

.styles_archiveButtonWrap__UB2BC .styles_openArchive__mMjfq {
		width: max-content;
		padding-bottom: 3px;
	}

.styles_actionsContainer__ebr_R {
	z-index: 1;
	position: fixed;
	width: 100%;

	transform-origin: bottom;

	filter: drop-shadow(0px 8px 10px rgba(10, 36, 50, 0.2));
}

@media screen and (max-width: 767px) {
		.styles_actionsContainer__ebr_R[data-show='false'] {
			bottom: -100%;
		}

		.styles_actionsContainer__ebr_R[data-show='true'] {
			transition: bottom 250ms ease-in-out;
			bottom: 20px;
		}
	}

@media screen and (min-width: 768px) {

.styles_actionsContainer__ebr_R {
		transition: bottom 350ms ease-in-out
}

		.styles_actionsContainer__ebr_R[data-show='false'] {
			bottom: -100%;
		}

		.styles_actionsContainer__ebr_R[data-show='true'] {
			bottom: 70px;
		}
	}

.styles_paginationContainer__GsgtS {
	user-select: none;
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 20px;

	padding: 30px;
}

.styles_paginationContainer__GsgtS p {
		margin: 0;
	}

.styles_paginationContainer__GsgtS .styles_pageNumber__OkkkV {
		font-size: 15px;
		line-height: 1.46;
		cursor: pointer;
	}

.styles_paginationContainer__GsgtS .styles_pageNumber__OkkkV[data-active='true'] {
			color: #0e90fb;
		}

.styles_paginationContainer__GsgtS .styles_pagination__vBhFq {
		display: flex;
		align-items: center;
		gap: 16px;
	}

@media screen and (max-width: 991px) {

.styles_paginationContainer__GsgtS .styles_pagination__vBhFq {
			gap: 13px
	}
		}

.styles_paginationContainer__GsgtS .styles_pagination__vBhFq p:not(.styles_threeDots__QLvk7) {
			cursor: pointer;
		}

.styles_paginationContainer__GsgtS .styles_pagination__vBhFq p:not(.styles_threeDots__QLvk7):hover {
				font-weight: 500;
			}

.styles_paginationContainer__GsgtS .styles_paginationArrow__DOvEH {
		cursor: pointer;
		display: flex;
		align-items: center;
		gap: 4px;
		font-size: 12px;
		line-height: 1.33;
	}

.styles_paginationContainer__GsgtS .styles_paginationArrow__DOvEH:hover {
			font-weight: 500;
		}

.styles_paginationContainer__GsgtS .styles_leftArrow__oUlnp img {
			transform: rotateY(180deg);
		}

.styles_button__TqHQc {
	border: none;
	transition: all 100ms ease-in-out;
	font-weight: 600;
	border-radius: 4px;
	cursor: pointer;
}

.styles_button__TqHQc:disabled {
	opacity: 0.6;
}

/*primary*/

.styles_button__TqHQc[data-background='bg-primary'] {
	background-color: var(--secondary-blue);
	color: white;
}

.styles_button__TqHQc[data-background='bg-primary']:hover:not(:disabled) {
	background-color: var(--primary-red-hover);
}

.styles_button__TqHQc[data-background='bg-primary']:active:not(:disabled) {
	background-color: var(--primary-red-passed);
}

/*danger*/

.styles_button__TqHQc[data-background='bg-danger'] {
	background-color: var(--primary-red);
	color: white;
}

.styles_button__TqHQc[data-background='bg-danger']:hover:not(:disabled) {
	background-color: var(--primary-red-hover);
}

.styles_button__TqHQc[data-background='bg-danger']:active:not(:disabled) {
	background-color: var(--primary-red-passed);
}

/*outline*/

.styles_button__TqHQc[data-background='bg-outline'] {
	color: var(--primary-black);
	border: 1px solid var(--secondary-grey);
	background-color: #ffffff;
}

.styles_button__TqHQc[data-background='bg-outline']:hover {
	background-color: var(--background-item-hover-2);
}

.styles_button__TqHQc[data-background='bg-outline-grey'] {
	color: var(--primary-black);
	border: 1px solid var(--secondary-grey);
}

/*green*/

.styles_button__TqHQc[data-background='bg-green'] {
	color: white;
	background-color: var(--primary-green);
}

.styles_button__TqHQc[data-background='bg-green']:hover:not(:disabled) {
	background-color: var(--primary-green);
}

.styles_button__TqHQc[data-background='bg-green']:active:not(:disabled) {
	background-color: var(--primary-green);
}

.styles_button__TqHQc[data-size='large'] {
	height: 56px;
	padding: 0 24px;
	font-size: 18px;
	line-height: 24px;
}

.styles_button__TqHQc[data-size='middle'] {
	height: 44px;
	padding: 0 16px;
	font-size: 14px;
	line-height: 24px;
}

.styles_button__TqHQc[data-size='premiddle'] {
	height: 40px;
	border-radius: 6px;
	padding: 0 16px;
	font-size: 14px;
	line-height: 24px;
}

.styles_button__TqHQc[data-size='small'] {
	height: 32px;
	padding: 0 12px;
	font-size: 14px;
	font-weight: 400;
	line-height: 22px;
	border-radius: 6px;
}

.styles_formInput___eQle {
	border-radius: 4px;
	border: 1px solid var(--secondary-grey);
	background: #ffffff !important;
	height: 44px;
	/*min-width: 281px;*/
	padding-top: 15px;
	padding-left: 12px;
	padding-right: 12px;
	width: 100%;
	color: #333333;
}
	.styles_formInput___eQle[data-variant='noLabel'] {
		padding-top: 0;
	}
	.styles_formInput___eQle {
	position: static !important;
}
	.styles_formInput___eQle[data-variant='profile'] {
		border-radius: 8px;
		border: 1px solid #dbe4f0;
	}
	.styles_formInput___eQle[data-variant='profile']:disabled {
			color: #758295;
		}

.styles_formInput___eQle:-webkit-autofill {
	background: #ffffff !important;
	transition: background-color 5000s ease-in-out;
}

.styles_formInputAutoFill__gdcao[data-disable-fill-color='true'] {
	-webkit-text-fill-color: white;
}

.styles_formInput___eQle:focus,
.styles_formInput___eQle:focus-visible {
	border-color: var(--input-border-focus-color);
	outline: none;
}

.styles_formInput___eQle:disabled {
	background-color: var(--input-disabled) !important;
}
.styles_formInput___eQle[data-variant='profile'][data-without-border='true'] {
		border: none;
	}
.styles_labelPlaceholder__6hSCm {
	text-wrap: nowrap;
	text-overflow: ellipsis;
	max-width: calc(100% - 24px);
	overflow: hidden;
}
.styles_labelPlaceholder__6hSCm:hover:not([data-disabled='true']) {
		z-index: 1000;
		max-width: unset;
		background: white;
		padding-right: 10px;
	}
.styles_labelPlaceholder__6hSCm {

	font-size: 14px;
	position: absolute;
	left: 12px;
	top: 12px;
	color: #959595;
	transition: all 200ms ease-in-out;
}

.styles_container__Pmbwb {
	position: relative;
}

.styles_container__Pmbwb[data-variant='profile'] .styles_labelPlaceholder__6hSCm {
			color: var(--primary-grey);
		}

.styles_labelPlaceholder__6hSCm[data-focus='true'] {
	font-size: 12px;
	top: 5px;
}

.styles_isInvalid__kCHpS > input {
	border-color: var(--primary-red) !important;
}

.styles_isInvalid__kCHpS > input:focus,
.styles_isInvalid__kCHpS > input:focus-visible {
	border-color: var(--primary-red) !important;
}

.styles_isInvalid__kCHpS ~ .styles_errorInput__2k5LT {
	color: var(--primary-red) !important;
	font-size: 12px !important;
	margin-left: 1px !important;
	transition: all 0.25s linear !important;
}

.styles_clearButton__uYbn0 {
	position: absolute;
	top: 22px;
	right: 22px;
	transform: translate(50%, -50%);
	cursor: pointer;
}

.styles_errorContainer__6uJS7 {
    color: var(--primary-red) !important;
    font-size: 12px !important;
    margin-left: 1px;
    transition: height 300ms ease-in-out !important;
}
.styles_container__p6yX2 {
	position: relative;
}

	.styles_container__p6yX2 .styles_input__vAxi5 {
		padding-right: 38px;
	}

	.styles_container__p6yX2 > a {
		position: absolute;
		right: 12px;
		top: 10px;
		cursor: pointer;
	}

.styles_container__S8rRo {
	background-color: #ffffff;
	border-radius: 4px;
	padding: 24px;
	z-index: 1;
}

.styles_container__S8rRo[data-clickable='true'] {
	cursor: pointer;
}

.styles_container__S8rRo[data-background='light-blue'] {
	background-color: var(--blue-0);
}

.styles_container__S8rRo[data-background='light-grey'] {
	background-color: var(--background-block-light-grey);
}

.styles_container__S8rRo[data-box-shadow='true'] {
	box-shadow: 0 1px 3px 1px rgba(10, 36, 50, 0.12);
}

.tooltip_block_tooltipIcon__e50ut {
	position: relative;
	display: inline;
	height: 24px;
}
.tooltip_block_tooltipIcon__e50ut:hover .tooltip_block_tooltip__GmtGA {
	transform: rotate3d(0, 0, 0, 90deg);
}
.tooltip_block_tooltipIcon__e50ut > .tooltip_block_tooltip__GmtGA {
	transform: rotate3d(1, 0, 0, 90deg);
	transform-origin: bottom;
	transition: all 300ms ease-in-out;
	font-weight: 400;
	font-size: 12px;
	line-height: 22px;
	padding: 8px;
	width: 262px;
	position: absolute;
	bottom: 30px;
	left: 0;
}
@media screen and (max-width: 1024px) {
	.tooltip_block_tooltipIcon__e50ut > .tooltip_block_tooltip__GmtGA {
		left: -131px;
	}
}

/*# sourceMappingURL=tooltip.block.module.css.map */

.styles_container__WuNXZ {
	display: flex;
	align-items: stretch;
	border: 1px solid var(--tabs-border-color);
	border-radius: 4px;
	padding: 6px;
	position: relative;
}

.styles_containerDisabled__4ZKV7 {
	background-color: var(--tab-disabled);
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	opacity: 0.4;
}

.styles_tab__2tioE {
	padding: 8px 12px;
	background-color: var(--white);
	border-radius: 2px;
	display: flex;
	align-items: center;
	justify-content: center;
	font-weight: 500;
	font-size: 14px;
	line-height: 22px;
	color: var(--primary-light-grey);
	cursor: pointer;
	margin-right: 4px;
	text-align: center;
}

@media screen and (max-width: 1024px) {

.styles_tab__2tioE {
		font-size: 12px;
		line-height: 16px;
		word-break: break-all
}
	}

.styles_tab__2tioE:last-child {
	margin-right: 0;
}

.styles_tab__2tioE:nth-last-child(1) {
	margin-right: 0;
}

.styles_tab__2tioE[data-active='true'] {
	background-color: var(--secondary-blue);
	color: var(--white);
}

.styles_tabDivider__qAMwV {
	height: 100%;
	width: 1px;
	background-color: var(--secondary-grey);
	margin-right: 4px;
}

.styles_tabDividerSmall__NehDL {
}

.styles_tabDividerLarge__4M8jI {
}

.styles_tabSmall__AOIKO {
	flex: 1;
	min-height: 40px;
}

.styles_tabLarge__JcTC_ {
	flex: 1;
	min-height: 60px;
}

.styles_dFlex__5Ea3a {
	display: flex;
}

.styles_tabsContainer__RGMTp {
	background-color: var(--white);
}

.styles_tab__ACccI {
	border-radius: 4px;
}

.styles_container__CCKIT {
	display: flex;
	flex-direction: column;
	gap: 16px;
}

	.styles_container__CCKIT .styles_titleBlock__hPttU {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		gap: 4px;
	}

	.styles_container__CCKIT .styles_titleBlock__hPttU .styles_title__nH6PV {
			font-weight: 600;
			font-size: 18px;
			line-height: 22px;
			color: var(--primary-black);
		}

	.styles_container__CCKIT .styles_errorText__UVEpN {
		font-weight: 400;
		font-size: 12px;
		line-height: 14px;
		color: var(--primary-red);

		margin-bottom: 8px;
	}

.styles_tabsContainer__yYs9O {
	background-color: var(--white);
}

.styles_tab__AR_EY {
	border-radius: 4px;
}

.styles_container__B_9Mm {
	display: flex;
	flex-direction: column;
	gap: 16px;
}

	.styles_container__B_9Mm .styles_title__oO94w,
	.styles_container__B_9Mm .styles_title__oO94w > span {
		font-weight: 600;
		font-size: 18px;
		line-height: 22px;
		color: var(--primary-black);

		position: relative;

		display: inline-flex;
		align-items: center;
		justify-content: flex-start;
		gap: 4px;
	}

.styles_container__d9GVG {
	padding: 0 30px 30px 30px;
	color: black;
	text-align: center;
	max-width: 624px;
}

	@media screen and (max-width: 992px) {.styles_container__d9GVG {
		padding: 0 16px 16px 16px
}
	}

.styles_iconBlock__vXjVh {
	display: flex;
	justify-content: center;
	margin-bottom: 16px;
}

.styles_iconBlock__vXjVh > img {
		width: 120px;
		height: 120px;
	}

.styles_titleBlock__CqaUL {
	font-size: 32px;
	font-style: normal;
	font-weight: 700;
	line-height: 40px;
	margin-bottom: 16px;
}

@media screen and (max-width: 992px) {

.styles_titleBlock__CqaUL {
		font-size: 20px;
		line-height: 24px
}
	}

.styles_description__Yz20i {
	font-size: 14px;
	font-weight: 400;
	line-height: 21px;
	margin-bottom: 24px;
	overflow-wrap: break-word;
}

.styles_buttonBlock__15b_Z {
	display: flex;
	gap: 20px;
}

.styles_buttonBlock__15b_Z button {
		max-width: 311px;
	}

.styles_buttonBlock__15b_Z > div {
	width: 100%;
}

@media screen and (max-width: 768px) {
	.styles_buttonBlock__15b_Z {
		flex-direction: column;
	}
}

.styles_container__y1trs {
	position: relative;
}

	.styles_container__y1trs .styles_input__vYe5_ {
		padding-right: 40px;
	}

	.styles_container__y1trs .styles_sendIcon__pj_d_ {
		width: 24px;
		height: 24px;
		background-repeat: no-repeat;
		position: absolute;
		top: 10px;
		right: 12px;
	}

	.styles_container__y1trs .styles_sendIcon__pj_d_[aria-disabled='false'] {
			cursor: pointer;
		}

.styles_commentBlock__kmg_e {
	padding: 24px 16px;
}

	.styles_commentBlock__kmg_e .styles_title__YTKuD {
		font-size: 14px;
		font-weight: 500;
		line-height: 1.14;
	}

	.styles_commentBlock__kmg_e .styles_commentBlockList__FTIK2 {
		max-height: 500px;
		overflow-y: auto;

		margin-bottom: 24px;
	}

	.styles_commentBlock__kmg_e .styles_commentBlockList__FTIK2 .styles_comment__dpZqB {
			display: flex;
			flex-direction: column;
			gap: 6px;

			font-size: 14px;
			line-height: 1.14;
		}

	@media screen and (max-width: 1439px) {.styles_commentBlock__kmg_e {
		padding: 12px 12px
}
	}

.styles_link__oFKkF {
	text-decoration: underline;
	color: #0e90fb;
}

.styles_container__j6dFw {
	padding: 8px 16px 40px;
	word-break: break-all;
}

	.styles_container__j6dFw .styles_regularTextBlock__AxD4g {
		margin: 0;
		font-size: 14px;
		line-height: 1.14;
	}

	.styles_container__j6dFw .styles_lightText__8h3k_ {
		color: var(--primary-light-grey);
	}

	.styles_container__j6dFw .styles_boldTextBlock__sPNwV {
		margin: 0;
		font-size: 16px;
		font-weight: 500;
		line-height: 1;
	}

	.styles_container__j6dFw .styles_infoContainer__6Miag[data-has-discussion='true'] {
			display: flex;
			gap: 24px;
		}

	.styles_container__j6dFw .styles_infoContainer__6Miag[data-has-discussion='true'] .styles_infoBlock__obOua {
				width: calc(50% + 80px);
			}

	.styles_container__j6dFw .styles_infoContainer__6Miag[data-has-discussion='true'] .styles_discussionBlock__CVNrP {
				width: calc(50% - 80px);
				display: grid;
				gap: 8px;
				grid-template-columns: 1fr 1fr;
				align-items: center;
			}

	.styles_container__j6dFw .styles_infoContainer__6Miag .styles_infoBlock__obOua {
			display: grid;
			grid-template-columns: auto 1fr;
			gap: 6px 24px;
			margin-bottom: 24px;
		}

	.styles_container__j6dFw .styles_footerBlock__uOgln {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	.styles_container__j6dFw .styles_priceBlock__Lvbo2 {
		margin-left: auto;
		display: grid;
		grid-template-columns: 1fr auto;
		gap: 6px 24px;
		margin-top: 24px;
	}

	.styles_container__j6dFw .styles_priceBlock__Lvbo2 p:nth-child(even) {
			text-align: right;
		}

	.styles_container__j6dFw .styles_cancelReasonBlock__4OrNH {
		color: var(--primary-grey);
	}

.styles_container__whtf9 {
	border-top: 1px solid var(--secondary-light-grey);
	border-bottom: 1px solid var(--secondary-light-grey);
	background: var(--background-block-light-grey);
	display: flex;
	flex-direction: column;
	gap: 12px;
	padding: 12px 0;
	word-break: break-all;
}

	.styles_container__whtf9 .styles_boldTextBlock__CW7K8 {
		margin: 0;
		font-size: 14px;
		font-weight: 500;
		line-height: 1.3;
	}

	.styles_container__whtf9 .styles_smallBoldTextBlock__lznjd {
		margin: 0;
		font-size: 12px;
		font-weight: 500;
	}

	.styles_container__whtf9 .styles_regularTextBlock__nCYxk {
		margin: 0;
		font-size: 14px;
		line-height: 1.3;
	}

	.styles_container__whtf9 .styles_smallRegularTextBlock__Y5iyn {
		margin: 0;
		font-size: 12px;
	}

	.styles_container__whtf9 .styles_link__bfn_0 {
		text-decoration: none;
		margin: 0;
		cursor: pointer;
		color: var(--secondary-blue);
		text-overflow: ellipsis;
		word-break: break-all;
		font-size: 12px;
		line-height: 1.3;
	}

	.styles_container__whtf9 .styles_row__0fAla {
		padding: 0 12px;
		display: flex;
		justify-content: space-between;
		align-items: baseline;
		column-gap: 20px;
	}

	.styles_container__whtf9 .styles_verticalRow__HScTo {
		padding: 0 12px;
		display: flex;
		flex-direction: column;
		gap: 4px;
	}

	.styles_container__whtf9 .styles_discussionBlock__UuSBd {
		display: grid;
		gap: 8px;
		grid-template-columns: 1fr 1fr;
	}

	.styles_container__whtf9 .styles_cancelReasonBlock__3GWbF {
		padding: 0 12px;
		color: var(--primary-grey);
	}

	@media screen and (max-width: 419px) {

	.styles_container__whtf9 .styles_cancelReasonBlock__3GWbF {
			font-size: 12px
	}
		}

	.styles_container__whtf9 .styles_priceBlock__2Rp9E {
		padding: 0 12px;
		display: grid;
		grid-template-columns: 1fr auto;
		gap: 4px 16px;
	}

	.styles_container__whtf9 .styles_priceBlock__2Rp9E p:nth-child(even) {
			text-align: right;
		}

.styles_container__qPWQZ {
	position: fixed;
	right: 20px;
}

.styles_containerDesktop__8m3Ob {
	width: 100px;
	height: 100px;
	bottom: 50px;
}

.styles_containerMobile__ZqAia {
	width: 100px;
	height: 100px;
	bottom: 5px;
	right: 0;
}

.styles_spinner__nVtwu {
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	width: 50px;
	height: 50px;
}

.styles_uiProgressSpinner___cpZ_ {
	position: relative;
	margin: 0 auto;
	width: 100%;
	height: 100%;
	display: inline-block;
}

.styles_uiProgressSpinnerSvg__xeuMA {
	animation: styles_uiProgressSpinnerRotate__jKatb 2s linear infinite;
	height: 100%;
	transform-origin: center center;
	width: 100%;
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	margin: auto;
}

.styles_uiProgressSpinnerCircle__pqoxZ {
	stroke-dasharray: 89, 200;
	stroke-dashoffset: 0;
	stroke: #d62d20;
	-webkit-animation:
		styles_uiProgressSpinnerDash__eOQZz 1.5s ease-in-out infinite,
		styles_uiProgressSpinnerColor__ehxwy 6s ease-in-out infinite;
	animation:
		styles_uiProgressSpinnerDash__eOQZz 1.5s ease-in-out infinite,
		styles_uiProgressSpinnerColor__ehxwy 6s ease-in-out infinite;
	strokelinecap: round;
}

@keyframes styles_uiProgressSpinnerDash__eOQZz {
	0% {
		stroke-dasharray: 1, 200;
		stroke-dashoffset: 0;
	}
	50% {
		stroke-dasharray: 89, 200;
		stroke-dashoffset: -35px;
	}
	100% {
		stroke-dasharray: 89, 200;
		stroke-dashoffset: -124px;
	}
}

@keyframes styles_uiProgressSpinnerColor__ehxwy {
	0%,
	100% {
		stroke: #d62d20;
	}
	40% {
		stroke: #0057e7;
	}
	66% {
		stroke: #008744;
	}
	80%,
	90% {
		stroke: #ffa700;
	}
}

@keyframes styles_uiProgressSpinnerRotate__jKatb {
	100% {
		transform: rotate(1turn);
	}
}

.styles_container__3u8QU {
	background: rgba(0, 0, 0, 0);
	position: fixed;
	height: 100vh;
	width: 100vw;
	top: 0;
	left: 0;
	visibility: hidden;
	transition: all 300ms ease-in-out;
	z-index: 10;
}

.friend-pay-page-content_title__4PDuH {
	font-size: 32px;
	font-style: normal;
	font-weight: 700;
	line-height: 40px;
	color: #333;
}

.friend-pay-page-content_titleBlock__1lyhX {
	display: grid;
	grid-template-columns: 1fr;
	gap: 12px;
}

.friend-pay-page-content_titleBlock__1lyhX > div {
		display: grid;
		grid-template-columns: 1fr;
		gap: 4px;
	}

.friend-pay-page-content_titleBlock__1lyhX .friend-pay-page-content_subtitle__LQ2cL {
		font-size: 14px;
		font-weight: 500;
		line-height: 22px;
	}

.friend-pay-page-content_titleBlock__1lyhX .friend-pay-page-content_titleLink__lI1Zd {
		font-size: 14px;
		font-weight: 500;
		color: #0061af;
		line-height: 22px;
		cursor: pointer;
	}

.friend-pay-page-content_titleBlock__1lyhX .friend-pay-page-content_titleLink__lI1Zd:hover {
			text-decoration-line: underline;
		}

.friend-pay-page-content_form__bUmmk {
	display: grid;
	gap: 20px;
}

.friend-pay-page-content_form__bUmmk .friend-pay-page-content_tabs__NZvlK {
		grid-area: 1 / 1 / 2 / 3;
	}

.friend-pay-page-content_form__bUmmk .friend-pay-page-content_namePrice__FUrw4 {
		grid-area: 2 / 1 / 2 / 3;
		display: grid;
		grid-template-columns: 1fr 2fr;
		gap: 20px;
	}

.friend-pay-page-content_form__bUmmk .friend-pay-page-content_coinRefresh__vnCBV {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		gap: 8px;
		width: 116px;
		color: var(--primary-blue);
		font-size: 12px;
		cursor: pointer;
	}

.friend-pay-page-content_form__bUmmk .friend-pay-page-content_coinRefresh__vnCBV > img {
			width: 24px;
			height: 24px;
		}

.friend-pay-page-content_form__bUmmk .friend-pay-page-content_linkProduct__FBeDx {
		grid-area: 3 / 1 / 3 / 3;
	}

.friend-pay-page-content_form__bUmmk .friend-pay-page-content_orderPaymentLink__HcXUK {
		grid-area: 4 / 1 / 4 / 3;
	}

.friend-pay-page-content_form__bUmmk .friend-pay-page-content_linkQrCode__MrSF2 {
		grid-area: 5 / 1 / 5 / 3;
	}

.friend-pay-page-content_form__bUmmk .friend-pay-page-content_summary__oourE {
		grid-area: 6 / 1 / 6 / 3;

		text-align: right;
		display: inline-flex;
		justify-content: flex-end;
		flex-direction: column;
		gap: 4px;
	}

.friend-pay-page-content_form__bUmmk .friend-pay-page-content_summary__oourE span:nth-child(1),
		.friend-pay-page-content_form__bUmmk .friend-pay-page-content_summary__oourE span:nth-child(2) {
			font-size: 12px;
			font-weight: 500;
			color: var(--primary-light-grey);
		}

.friend-pay-page-content_form__bUmmk .friend-pay-page-content_summary__oourE span:nth-child(3),
		.friend-pay-page-content_form__bUmmk .friend-pay-page-content_summary__oourE span:nth-child(4) {
			font-size: 18px;
			font-weight: 600;
			color: var(--primary-text-dark);
		}

.friend-pay-page-content_form__bUmmk .friend-pay-page-content_buttons__1Q0w2 {
		grid-area: 7 / 1 / 7 / 1;

		display: grid;
		gap: 20px;

		grid-template-columns: 250px 150px;
	}

@media screen and (max-width: 991.98px) {

.friend-pay-page-content_form__bUmmk {
		gap: 12px
}

		.friend-pay-page-content_form__bUmmk .friend-pay-page-content_namePrice__FUrw4 {
			grid-area: 2 / 1 / 2 / 3;

			display: grid;
		}

			.friend-pay-page-content_form__bUmmk .friend-pay-page-content_namePrice__FUrw4 > div:nth-child(1) {
				grid-area: 1 / 1 / 1 / 4;
			}

			.friend-pay-page-content_form__bUmmk .friend-pay-page-content_namePrice__FUrw4 > div:nth-child(2) {
				grid-area: 2 / 1 / 2 / 4;
			}

		.friend-pay-page-content_form__bUmmk .friend-pay-page-content_coinRefresh__vnCBV {
		}

		.friend-pay-page-content_form__bUmmk .friend-pay-page-content_linkProduct__FBeDx {
			grid-area: 3 / 1 / 3 / 3;
		}

		.friend-pay-page-content_form__bUmmk .friend-pay-page-content_orderPaymentLink__HcXUK {
			grid-area: 4 / 1 / 4 / 3;
		}

		.friend-pay-page-content_form__bUmmk .friend-pay-page-content_linkQrCode__MrSF2 {
			grid-area: 5 / 1 / 5 / 3;
		}

		.friend-pay-page-content_form__bUmmk .friend-pay-page-content_summary__oourE {
			grid-area: 6 / 1 / 6 / 3;
		}

		.friend-pay-page-content_form__bUmmk .friend-pay-page-content_buttons__1Q0w2 {
			grid-area: 7 / 1 / 7 / 3;
			gap: 8px;
			grid-template-columns: 1fr;
		}
	}

.buyouts-list-item_container__ZoHrI {
	border-radius: 4px;
	border: 1px solid #ccc;
}

	@media screen and (max-width: 1439px) {
		.buyouts-list-item_container__ZoHrI .buyouts-list-item_mobileView__SG5qf {
			display: block;
		}

		.buyouts-list-item_container__ZoHrI .buyouts-list-item_desktopView__25ohY {
			display: none;
		}
	}

	@media screen and (min-width: 1440px) {
		.buyouts-list-item_container__ZoHrI .buyouts-list-item_desktopView__25ohY {
			display: block;
		}

		.buyouts-list-item_container__ZoHrI .buyouts-list-item_mobileView__SG5qf {
			display: none;
		}
	}

	.buyouts-list-item_container__ZoHrI .buyouts-list-item_regularTextBlock___2l3v {
		font-size: 14px;
		margin: 0;
	}

	.buyouts-list-item_container__ZoHrI .buyouts-list-item_additionalContentContainer__wcW_8 {
		overflow: hidden;
		transition:
			height 250ms ease-in-out,
			opacity 250ms ease-in-out;
	}

	.buyouts-list-item_container__ZoHrI .buyouts-list-item_actionButton__sspSB {
		text-decoration: none;
		margin: 0;
		user-select: none;
		cursor: pointer;
		color: var(--secondary-blue);
		font-size: 14px;
		font-weight: 500;
		white-space: nowrap;
	}

	.buyouts-list-item_container__ZoHrI .buyouts-list-item_desktopView__25ohY .buyouts-list-item_mainContent__euOct {
			cursor: pointer;
			height: 64px;
			padding: 16px;
			display: grid;
			grid-template-columns: 225px 1fr 70px 140px 37px 68px 24px;
			align-items: center;
			gap: 10px;
		}

	.buyouts-list-item_container__ZoHrI .buyouts-list-item_desktopView__25ohY .buyouts-list-item_mainContent__euOct .buyouts-list-item_headBlock__rBPQV {

				display: grid;
				grid-template-columns: auto 1fr;
				align-items: center;
				gap: 8px;
			}

	.buyouts-list-item_container__ZoHrI .buyouts-list-item_desktopView__25ohY .buyouts-list-item_mainContent__euOct .buyouts-list-item_headBlock__rBPQV .buyouts-list-item_checkBox__ruVDT {
				}

	.buyouts-list-item_container__ZoHrI .buyouts-list-item_desktopView__25ohY .buyouts-list-item_mainContent__euOct .buyouts-list-item_dateOfCreateBlock__tzuJB {}

	.buyouts-list-item_container__ZoHrI .buyouts-list-item_desktopView__25ohY .buyouts-list-item_mainContent__euOct .buyouts-list-item_statusBlock__wBcIh {
				min-width: 0;
			}

	.buyouts-list-item_container__ZoHrI .buyouts-list-item_desktopView__25ohY .buyouts-list-item_mainContent__euOct .buyouts-list-item_priceBlock__3GuQg .buyouts-list-item_price__DDxbQ {
					margin: 0;
					font-size: 14px;
					font-weight: 500;
				}

	.buyouts-list-item_container__ZoHrI .buyouts-list-item_desktopView__25ohY .buyouts-list-item_mainContent__euOct .buyouts-list-item_actionBlock__stbmL {
				flex-grow: 1;
				display: flex;
				flex-direction: column;
				gap: 4px;
				align-items: flex-start;
			}

	.buyouts-list-item_container__ZoHrI .buyouts-list-item_desktopView__25ohY .buyouts-list-item_mainContent__euOct .buyouts-list-item_productPhotosBlock__ntnpZ {
				height: 32px;
				overflow: hidden;
			}

	.buyouts-list-item_container__ZoHrI .buyouts-list-item_desktopView__25ohY .buyouts-list-item_mainContent__euOct .buyouts-list-item_toggleBlock__HJh48 {
				display: flex;
				align-items: center;
			}

	.buyouts-list-item_container__ZoHrI .buyouts-list-item_desktopView__25ohY .buyouts-list-item_mainContent__euOct .buyouts-list-item_toggleBlock__HJh48 > img {
					cursor: pointer;
					transform: rotate(90deg);
					transition: transform 250ms ease-in-out;
					width: 24px;
				}

	.buyouts-list-item_container__ZoHrI .buyouts-list-item_desktopView__25ohY .buyouts-list-item_mainContent__euOct .buyouts-list-item_toggleBlock__HJh48[data-opened='true'] img {
					transform: rotate(-90deg);
				}

	.buyouts-list-item_container__ZoHrI .buyouts-list-item_productMessageBlock__QYg6z {
		position: relative;
	}

	.buyouts-list-item_container__ZoHrI .buyouts-list-item_productMessageBlock__QYg6z > img {
			width: 24px;
			height: 24px;
		}

	.buyouts-list-item_container__ZoHrI .buyouts-list-item_productMessageBlock__QYg6z .buyouts-list-item_productNewMessage__QLh0K {
			position: absolute;
			top: -10px;
			right: 3px;
		}

	.buyouts-list-item_container__ZoHrI .buyouts-list-item_productMessageBlock__QYg6z .buyouts-list-item_productNewMessage__QLh0K[data-mobile='true'] {
				right: -7px;
			}

	.buyouts-list-item_container__ZoHrI .buyouts-list-item_productMessageBlock__QYg6z .buyouts-list-item_productNewMessage__QLh0K {

			display: flex;
			align-items: center;
			justify-content: center;

			width: 22px;
			height: 22px;

			background: white;
			border-radius: 50%;
}

	.buyouts-list-item_container__ZoHrI .buyouts-list-item_productMessageBlock__QYg6z .buyouts-list-item_productNewMessage__QLh0K > div {
				display: flex;
				align-items: center;
				justify-content: center;

				font-size: 12px;
				color: white;

				width: 18px;
				height: 18px;

				background: red;
				border-radius: 50%;
			}

	.buyouts-list-item_container__ZoHrI .buyouts-list-item_mobileView__SG5qf .buyouts-list-item_mainContent__euOct {
			cursor: pointer;
			padding: 12px;
			display: flex;
			flex-direction: column;
			gap: 12px;
		}

	.buyouts-list-item_container__ZoHrI .buyouts-list-item_mobileView__SG5qf .buyouts-list-item_mainContent__euOct .buyouts-list-item_row__rwkHS {
				display: flex;
				justify-content: space-between;
				align-items: center;
			}

	.buyouts-list-item_container__ZoHrI .buyouts-list-item_mobileView__SG5qf .buyouts-list-item_mainContent__euOct .buyouts-list-item_checkBox__ruVDT {
				padding: 3px;
			}

	.buyouts-list-item_container__ZoHrI .buyouts-list-item_mobileView__SG5qf .buyouts-list-item_mainContent__euOct .buyouts-list-item_checkBox__ruVDT input {
					width: 18px;
					height: 18px;
				}

	.buyouts-list-item_container__ZoHrI .buyouts-list-item_mobileView__SG5qf .buyouts-list-item_mainContent__euOct .buyouts-list-item_middleRow__sNK_V {
				display: grid;
				grid-template-columns: auto 1fr;
				justify-content: flex-start;
				gap: 16px;
			}

	.buyouts-list-item_container__ZoHrI .buyouts-list-item_mobileView__SG5qf .buyouts-list-item_moreContainer__VKzIz .buyouts-list-item_toggleBlock__HJh48 {
				padding: 0 12px 12px;
				margin: 0;
				user-select: none;
				cursor: pointer;
				color: var(--secondary-blue);
				font-size: 14px;
				line-height: 1.57;
				display: flex;
				justify-content: center;
				gap: 4px;
			}

	.buyouts-list-item_container__ZoHrI .buyouts-list-item_mobileView__SG5qf .buyouts-list-item_moreContainer__VKzIz .buyouts-list-item_toggleBlock__HJh48 > img {
					cursor: pointer;
					transform: rotate(0deg);
					transition: transform 250ms ease-in-out;
					width: 24px;
				}

	.buyouts-list-item_container__ZoHrI .buyouts-list-item_mobileView__SG5qf .buyouts-list-item_moreContainer__VKzIz .buyouts-list-item_toggleBlock__HJh48[data-opened='true'] img {
					transform: rotate(-180deg);
				}

.empty-list-with-filters_emptyBlock__oKLCk {
	display: flex;
	justify-content: center;
	height: 100%;
}

	.empty-list-with-filters_emptyBlock__oKLCk .empty-list-with-filters_contentContainer__VzAxk {
		max-width: 454px;
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.empty-list-with-filters_emptyBlock__oKLCk .empty-list-with-filters_contentContainer__VzAxk .empty-list-with-filters_icon__XW2Zq {
			margin-bottom: 32px;
			width: 118px;
			height: 118px;
		}

	.empty-list-with-filters_emptyBlock__oKLCk .empty-list-with-filters_contentContainer__VzAxk .empty-list-with-filters_title__gUxPH {
			text-align: center;
			font-size: 32px;
			font-weight: 700;
			line-height: 1.25;
			margin: 0 0 15px 0;
		}

	.empty-list-with-filters_emptyBlock__oKLCk .empty-list-with-filters_contentContainer__VzAxk .empty-list-with-filters_description___NJtq {
			margin: 0 0 30px 0;
			text-align: center;
			font-size: 14px;
			line-height: 1.5;
		}

	.empty-list-with-filters_emptyBlock__oKLCk .empty-list-with-filters_contentContainer__VzAxk .empty-list-with-filters_goToCatalogButton__rRjAB {
			text-decoration: none;
			color: #ffffff;
			padding: 12px 20px;
			border-radius: 4px;
			background-color: #ec1944;
			margin: 0 auto;
		}

.empty-list-without-filters_emptyBlock__ak0dt {
	display: flex;
	justify-content: center;
	height: 100%;
}

	.empty-list-without-filters_emptyBlock__ak0dt .empty-list-without-filters_contentContainer__VKPNp {
		max-width: 454px;
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.empty-list-without-filters_emptyBlock__ak0dt .empty-list-without-filters_contentContainer__VKPNp .empty-list-without-filters_icon__3FeuF {
			margin-bottom: 32px;
			width: 118px;
			height: 118px;
		}

	.empty-list-without-filters_emptyBlock__ak0dt .empty-list-without-filters_contentContainer__VKPNp .empty-list-without-filters_title__TmwOe {
			margin-bottom: 15px;

			text-align: center;
			font-size: 32px;
			font-weight: 700;
			line-height: 1.25;
		}

	.empty-list-without-filters_emptyBlock__ak0dt .empty-list-without-filters_contentContainer__VKPNp .empty-list-without-filters_description__nfndr {
			margin-bottom: 30px;

			text-align: center;
			font-size: 14px;
			line-height: 1.5;
		}

	.empty-list-without-filters_emptyBlock__ak0dt .empty-list-without-filters_contentContainer__VKPNp .empty-list-without-filters_goToCatalogButton__VYL_q {
			text-decoration: none;
			color: #ffffff;
			padding: 12px 20px;
			border-radius: 4px;
			background-color: #ec1944;
			margin: 0 auto;
		}

.temporary-buyouts-list-item_container__pOf3A {
	border-radius: 4px;
	border: 1px solid #ccc;
	background-color: #f5f6fa;
}

	@media screen and (max-width: 1439px) {
		.temporary-buyouts-list-item_container__pOf3A .temporary-buyouts-list-item_mobileView__8lx5a {
			display: block;
		}

		.temporary-buyouts-list-item_container__pOf3A .temporary-buyouts-list-item_desktopView__fYgZP {
			display: none;
		}
	}

	@media screen and (min-width: 1440px) {
		.temporary-buyouts-list-item_container__pOf3A .temporary-buyouts-list-item_desktopView__fYgZP {
			display: block;
		}

		.temporary-buyouts-list-item_container__pOf3A .temporary-buyouts-list-item_mobileView__8lx5a {
			display: none;
		}
	}

	.temporary-buyouts-list-item_container__pOf3A .temporary-buyouts-list-item_regularTextBlock__6dZC2 {
		font-size: 14px;
		margin: 0;
	}

	.temporary-buyouts-list-item_container__pOf3A .temporary-buyouts-list-item_desktopView__fYgZP .temporary-buyouts-list-item_mainContent__YOhj7 {
			cursor: default;
			height: 64px;
			padding: 16px;
			display: grid;
			grid-template-columns: 225px 1fr 70px 140px 37px 68px 24px;
			align-items: center;
			gap: 10px;
		}

	.temporary-buyouts-list-item_container__pOf3A .temporary-buyouts-list-item_desktopView__fYgZP .temporary-buyouts-list-item_mainContent__YOhj7 .temporary-buyouts-list-item_headBlock__VzcRr {
				display: grid;
				grid-template-columns: auto 1fr;
				align-items: center;
				gap: 8px;
			}

	.temporary-buyouts-list-item_container__pOf3A .temporary-buyouts-list-item_desktopView__fYgZP .temporary-buyouts-list-item_mainContent__YOhj7 .temporary-buyouts-list-item_dateOfCreateBlock__2nesQ {
			}

	.temporary-buyouts-list-item_container__pOf3A .temporary-buyouts-list-item_desktopView__fYgZP .temporary-buyouts-list-item_mainContent__YOhj7 .temporary-buyouts-list-item_statusBlock__iWpVO {
				min-width: 0;
			}

	.temporary-buyouts-list-item_container__pOf3A .temporary-buyouts-list-item_desktopView__fYgZP .temporary-buyouts-list-item_mainContent__YOhj7 .temporary-buyouts-list-item_priceBlock__d9rha .temporary-buyouts-list-item_price__8yF_8 {
					margin: 0;
					font-size: 14px;
					font-weight: 500;
				}

	.temporary-buyouts-list-item_container__pOf3A .temporary-buyouts-list-item_desktopView__fYgZP .temporary-buyouts-list-item_mainContent__YOhj7 .temporary-buyouts-list-item_actionBlock__3itd8 {
			}

	.temporary-buyouts-list-item_container__pOf3A .temporary-buyouts-list-item_desktopView__fYgZP .temporary-buyouts-list-item_mainContent__YOhj7 .temporary-buyouts-list-item_productPhotosBlock__zTYFg {
				height: 32px;
				overflow: hidden;
			}

	.temporary-buyouts-list-item_container__pOf3A .temporary-buyouts-list-item_desktopView__fYgZP .temporary-buyouts-list-item_mainContent__YOhj7 .temporary-buyouts-list-item_toggleBlock__qsleH {
				display: flex;
				align-items: center;
			}

	.temporary-buyouts-list-item_container__pOf3A .temporary-buyouts-list-item_desktopView__fYgZP .temporary-buyouts-list-item_mainContent__YOhj7 .temporary-buyouts-list-item_toggleBlock__qsleH > img {
					cursor: pointer;
					transform: rotate(90deg);
					transition: transform 250ms ease-in-out;
					width: 24px;
				}

	.temporary-buyouts-list-item_container__pOf3A .temporary-buyouts-list-item_desktopView__fYgZP .temporary-buyouts-list-item_mainContent__YOhj7 .temporary-buyouts-list-item_toggleBlock__qsleH[data-opened='true'] img {
					transform: rotate(-90deg);
				}

	.temporary-buyouts-list-item_container__pOf3A .temporary-buyouts-list-item_productMessageBlock__z_GIA {
	}

	.temporary-buyouts-list-item_container__pOf3A .temporary-buyouts-list-item_mobileView__8lx5a .temporary-buyouts-list-item_mainContent__YOhj7 {
			cursor: default;
			padding: 12px;
			display: flex;
			flex-direction: column;
			gap: 12px;
		}

	.temporary-buyouts-list-item_container__pOf3A .temporary-buyouts-list-item_mobileView__8lx5a .temporary-buyouts-list-item_mainContent__YOhj7 .temporary-buyouts-list-item_row__xPlvN {
				display: flex;
				justify-content: space-between;
				align-items: center;
			}

	.temporary-buyouts-list-item_container__pOf3A .temporary-buyouts-list-item_mobileView__8lx5a .temporary-buyouts-list-item_mainContent__YOhj7 .temporary-buyouts-list-item_checkBox__m2aEQ {
				padding: 3px;
			}

	.temporary-buyouts-list-item_container__pOf3A .temporary-buyouts-list-item_mobileView__8lx5a .temporary-buyouts-list-item_mainContent__YOhj7 .temporary-buyouts-list-item_checkBox__m2aEQ input {
					width: 18px;
					height: 18px;
				}


.buyouts-page_pageTitle__fzNma {
	font-size: 32px;
	font-weight: 700;
	line-height: 1.25;
    margin-top: 0;
	margin-bottom: 20px;
}

.buyouts-page_filters__3PQXG {
	margin-bottom: 16px;
	display: flex;
	justify-content: space-between;
	gap: 10px;
}

.buyouts-page_list__gbuey {
	margin-top: 10px;
	display: flex;
	flex-direction: column;
	gap: 8px;
}

.buyouts-page_emptyBlock__Thgqg {
	display: flex;
	justify-content: center;
	height: 100%;
	padding-top: 100px;
}

.buyouts-page_emptyBlock__Thgqg .buyouts-page_contentContainer__wXVZN {
		max-width: 454px;
		display: flex;
		flex-direction: column;
		align-items: center;
	}

.buyouts-page_emptyBlock__Thgqg .buyouts-page_contentContainer__wXVZN .buyouts-page_icon__6FouT {
			margin-bottom: 32px;
			width: 118px;
			height: 118px;
		}

.buyouts-page_emptyBlock__Thgqg .buyouts-page_contentContainer__wXVZN .buyouts-page_title__WPdeC {
			margin-bottom: 15px;

			text-align: center;
			font-size: 32px;
			font-weight: 700;
			line-height: 1.25;
		}

.buyouts-page_emptyBlock__Thgqg .buyouts-page_contentContainer__wXVZN .buyouts-page_description__TZxuv {
			margin-bottom: 30px;

			text-align: center;
			font-size: 14px;
			line-height: 1.5;
		}

.buyouts-page_emptyBlock__Thgqg .buyouts-page_contentContainer__wXVZN .buyouts-page_goToCatalogButton__l7Rsw {
			text-decoration: none;
			color: #ffffff;
			padding: 12px 20px;
			border-radius: 4px;
			background-color: #ec1944;
			margin: 0 auto;
		}

.unexpected-error_errorContainer__rMhwG {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 20px;
	text-align: center;
	width: 100%;
	max-width: 600px;
	margin: 0 auto;
}

	.unexpected-error_errorContainer__rMhwG .unexpected-error_errorContent__jxhty {
		padding: 20px;
	}

	.unexpected-error_errorContainer__rMhwG .unexpected-error_errorContent__jxhty .unexpected-error_errorTitle__JJzpr {
			font-size: 3rem;
			color: #dc3545;
			margin: 0;
		}

	.unexpected-error_errorContainer__rMhwG .unexpected-error_errorContent__jxhty .unexpected-error_errorSubTitle__pMn99 {
			font-size: 1.5rem;
			color: #dc3545;
			margin-top: 0;
			margin-bottom: 15px;
		}

	.unexpected-error_errorContainer__rMhwG .unexpected-error_errorContent__jxhty .unexpected-error_errorDescription__7L8s0 {
			font-size: 1rem;
			color: #555;
			margin-bottom: 20px;
		}

	.unexpected-error_errorContainer__rMhwG .unexpected-error_errorContent__jxhty .unexpected-error_retryButton__BaTS5 {
			background-color: #007bff;
			color: white;
			border: none;
			padding: 10px 20px;
			font-size: 1rem;
			border-radius: 5px;
			cursor: pointer;
			transition: background-color 0.3s ease;
		}

	.unexpected-error_errorContainer__rMhwG .unexpected-error_errorContent__jxhty .unexpected-error_retryButton__BaTS5:hover {
				background-color: #0056b3;
			}

@media (max-width: 768px) {
	.unexpected-error_errorContainer__rMhwG {
		max-width: 90%;
	}
			.unexpected-error_errorContainer__rMhwG .unexpected-error_errorContent__jxhty .unexpected-error_errorTitle__JJzpr {
				font-size: 1.5rem;
			}

			.unexpected-error_errorContainer__rMhwG .unexpected-error_errorContent__jxhty .unexpected-error_errorDescription__7L8s0 {
				font-size: 0.9rem;
			}

			.unexpected-error_errorContainer__rMhwG .unexpected-error_errorContent__jxhty .unexpected-error_retryButton__BaTS5 {
				font-size: 0.9rem;
			}
}

.order-by-shop-page-content_container____CFz {
    position: relative;
	padding: 24px 0;
	height: 100%;
	display: grid;
	grid-template-columns: 1fr;
	grid-template-rows: auto 1fr;
	row-gap: 24px;
}

    @media (max-width: 61.99375em) {.order-by-shop-page-content_container____CFz {
        row-gap: 20px;
}
}

	.order-by-shop-page-content_container____CFz > h2 {
        font-weight: 500;
        font-size: 40px;
        line-height: 60px;
		color: #333333;
		margin: 0;
	}

	@media (max-width: 61.99375em) {

	.order-by-shop-page-content_container____CFz > h2 {
            font-weight: 500;
            font-size: 20px;
            line-height: 28px
	}
}

	.order-by-shop-page-content_container____CFz .order-by-shop-page-content_page__T8SYS {
		display: grid;
		grid-template-columns: 1fr 317px;
		column-gap: 26px;
	}

	@media (max-width: 61.99375em) {

	.order-by-shop-page-content_container____CFz .order-by-shop-page-content_page__T8SYS {
			grid-template-columns: 1fr;
			gap: 16px;
	}
}

	.order-by-shop-page-content_container____CFz .order-by-shop-page-content_page__T8SYS .order-by-shop-page-content_title__GxwZE {
            overflow: hidden;
            text-overflow: ellipsis;
            word-break: keep-all;
		}

	.order-by-shop-page-content_container____CFz .order-by-shop-page-content_page__T8SYS .order-by-shop-page-content_title__GxwZE span{
                font-weight: 500;
                font-size: 14px;
                line-height: 24px;
            }

	.order-by-shop-page-content_container____CFz .order-by-shop-page-content_page__T8SYS .order-by-shop-page-content_productInfo__Oq4CG {
			display: flex;
			flex-direction: column;
			gap: 6px;

			font-size: 14px;
			line-height: 16px;
		}

	@media (max-width: 61.99375em) {

	.order-by-shop-page-content_container____CFz .order-by-shop-page-content_page__T8SYS .order-by-shop-page-content_productInfo__Oq4CG {
				font-size: 12px;
		}
}

	.order-by-shop-page-content_container____CFz .order-by-shop-page-content_page__T8SYS .order-by-shop-page-content_productInfo__Oq4CG ul{
                padding: 0;
                margin:0;
                list-style-type: none;
            }

	.order-by-shop-page-content_container____CFz .order-by-shop-page-content_page__T8SYS .order-by-shop-page-content_productInfo__Oq4CG ul li{
                    display: grid;
                    grid-template-columns: 1fr 3fr;
                    gap: 4px;
                }

	@media (max-width: 61.99375em) {

	.order-by-shop-page-content_container____CFz .order-by-shop-page-content_page__T8SYS .order-by-shop-page-content_productInfo__Oq4CG ul li{
                        grid-template-columns: 1fr 3fr;
                }
}

	.order-by-shop-page-content_container____CFz .order-by-shop-page-content_page__T8SYS .order-by-shop-page-content_productInfo__Oq4CG ul li span{
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 24px;
                    }

	.order-by-shop-page-content_container____CFz .order-by-shop-page-content_page__T8SYS .order-by-shop-page-content_productInfo__Oq4CG ul li span:first-child{
                            color: var(--grey-4);
                        }

	@media (max-width: 61.99375em) {

	.order-by-shop-page-content_container____CFz .order-by-shop-page-content_page__T8SYS .order-by-shop-page-content_productInfo__Oq4CG ul li span:last-child{
                                white-space: nowrap;
                                overflow: hidden;
                                text-overflow: ellipsis
                        }
}

	.order-by-shop-page-content_container____CFz .order-by-shop-page-content_page__T8SYS .order-by-shop-page-content_listBlock__9YuTV {
			display: flex;
			flex-direction: column;
			gap: 4px;
		}

	.order-by-shop-page-content_container____CFz .order-by-shop-page-content_page__T8SYS .order-by-shop-page-content_listBlock__9YuTV .order-by-shop-page-content_blockTitleLabel__OGf4Y {
                font-weight: 400;
                font-size: 16px;
                line-height: 28px;
				margin-top: 0;
				margin-bottom: 4px;
			}

	.order-by-shop-page-content_container____CFz .order-by-shop-page-content_page__T8SYS .order-by-shop-page-content_listBlock__9YuTV .order-by-shop-page-content_input___rYSt {
				border: 1px solid var(--grey-2);
                border-radius: 12px;
			}

	.order-by-shop-page-content_container____CFz .order-by-shop-page-content_page__T8SYS .order-by-shop-page-content_listBlock__9YuTV .order-by-shop-page-content_input___rYSt::placeholder {
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 28px;
                    color: var(--grey-4);
                }

	.order-by-shop-page-content_container____CFz .order-by-shop-page-content_page__T8SYS .order-by-shop-page-content_listBlock__9YuTV .order-by-shop-page-content_additionalInfo__9gdXW {
                font-weight: 400;
                font-size: 14px;
                line-height: 24px;
                color: var(--grey-4);
			}

	@media (max-width: 61.99375em) {

	.order-by-shop-page-content_container____CFz .order-by-shop-page-content_page__T8SYS .order-by-shop-page-content_listBlock__9YuTV .order-by-shop-page-content_additionalInfo__9gdXW {
                    font-size: 12px
			}
}

	.order-by-shop-page-content_container____CFz .order-by-shop-page-content_page__T8SYS .order-by-shop-page-content_listBlock__9YuTV .order-by-shop-page-content_descriptionBlock__TX1SH {
				margin-bottom: 20px;
			}

	.order-by-shop-page-content_container____CFz .order-by-shop-page-content_page__T8SYS .order-by-shop-page-content_listBlock__9YuTV .order-by-shop-page-content_blockTitle__riVy_ {
                font-weight: 500;
                font-size: 20px;
                line-height: 28px;
                margin-top: 0;
                margin-bottom: 12px;
            }

	@media (max-width: 61.99375em) {

	.order-by-shop-page-content_container____CFz .order-by-shop-page-content_page__T8SYS .order-by-shop-page-content_listBlock__9YuTV .order-by-shop-page-content_blockTitle__riVy_ {
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 28px
            }
}

	.order-by-shop-page-content_container____CFz .order-by-shop-page-content_page__T8SYS .order-by-shop-page-content_listBlock__9YuTV .order-by-shop-page-content_listItem__OiqX8 {
				border: 1px solid var(--grey-2);
				border-radius: 12px;
				padding: 24px;

				display: flex;
				flex-direction: column;
				gap: 16px;
			}

	@media (max-width: 61.99375em) {

	.order-by-shop-page-content_container____CFz .order-by-shop-page-content_page__T8SYS .order-by-shop-page-content_listBlock__9YuTV .order-by-shop-page-content_listItem__OiqX8 {
                    padding: 16px 12px;
			}
}

	.order-by-shop-page-content_container____CFz .order-by-shop-page-content_page__T8SYS .order-by-shop-page-content_listBlock__9YuTV .order-by-shop-page-content_listItem__OiqX8 .order-by-shop-page-content_info__FuDb_ {
					display: grid;
					grid-template-columns: 140px 1fr;
					gap: 20px;
				}

	@media (max-width: 74.99375em) {

	.order-by-shop-page-content_container____CFz .order-by-shop-page-content_page__T8SYS .order-by-shop-page-content_listBlock__9YuTV .order-by-shop-page-content_listItem__OiqX8 .order-by-shop-page-content_info__FuDb_ {
						grid-template-columns: 1fr;
				}
}

	.order-by-shop-page-content_container____CFz .order-by-shop-page-content_page__T8SYS .order-by-shop-page-content_listBlock__9YuTV .order-by-shop-page-content_listItem__OiqX8 .order-by-shop-page-content_info__FuDb_ .order-by-shop-page-content_image__cRtnf {
                        overflow: hidden;
					}

	.order-by-shop-page-content_container____CFz .order-by-shop-page-content_page__T8SYS .order-by-shop-page-content_listBlock__9YuTV .order-by-shop-page-content_listItem__OiqX8 .order-by-shop-page-content_info__FuDb_ .order-by-shop-page-content_image__cRtnf .order-by-shop-page-content_imgItem__4wWrV {
							width: 140px;
							height: 140px;
                            border-radius: 12px;
						}

	.order-by-shop-page-content_container____CFz .order-by-shop-page-content_page__T8SYS .order-by-shop-page-content_listBlock__9YuTV .order-by-shop-page-content_listItem__OiqX8 .order-by-shop-page-content_info__FuDb_ .order-by-shop-page-content_image__cRtnf .order-by-shop-page-content_titleMobileBlock__c5Qpb {
							display: flex;
							flex-direction: column;
							gap: 16px;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            word-break: keep-all;
						}

	@media (max-width: 74.99375em) {

	.order-by-shop-page-content_container____CFz .order-by-shop-page-content_page__T8SYS .order-by-shop-page-content_listBlock__9YuTV .order-by-shop-page-content_listItem__OiqX8 .order-by-shop-page-content_info__FuDb_ .order-by-shop-page-content_image__cRtnf .order-by-shop-page-content_titleMobileBlock__c5Qpb {
                                gap: 8px;
						}
}

	.order-by-shop-page-content_container____CFz .order-by-shop-page-content_page__T8SYS .order-by-shop-page-content_listBlock__9YuTV .order-by-shop-page-content_listItem__OiqX8 .order-by-shop-page-content_info__FuDb_ .order-by-shop-page-content_image__cRtnf .order-by-shop-page-content_titleMobileBlock__c5Qpb span{
                                font-weight: 500;
                                font-size: 14px;
                                line-height: 24px;
                            }

	@media (max-width: 74.99375em) {

	.order-by-shop-page-content_container____CFz .order-by-shop-page-content_page__T8SYS .order-by-shop-page-content_listBlock__9YuTV .order-by-shop-page-content_listItem__OiqX8 .order-by-shop-page-content_info__FuDb_ .order-by-shop-page-content_image__cRtnf {
							display: flex;
							gap: 12px;
    }

							.order-by-shop-page-content_container____CFz .order-by-shop-page-content_page__T8SYS .order-by-shop-page-content_listBlock__9YuTV .order-by-shop-page-content_listItem__OiqX8 .order-by-shop-page-content_info__FuDb_ .order-by-shop-page-content_image__cRtnf .order-by-shop-page-content_imgItem__4wWrV {
								width: 60px;
								height: 60px;
							}
}

	.order-by-shop-page-content_container____CFz .order-by-shop-page-content_page__T8SYS .order-by-shop-page-content_listBlock__9YuTV .order-by-shop-page-content_listItem__OiqX8 .order-by-shop-page-content_info__FuDb_ .order-by-shop-page-content_description__araiL {
						display: flex;
						flex-direction: column;
						gap: 8px;
                        max-width: 100%;
                        overflow: hidden;
					}

	@media (max-width: 61.99375em) {

	.order-by-shop-page-content_container____CFz .order-by-shop-page-content_page__T8SYS .order-by-shop-page-content_listBlock__9YuTV .order-by-shop-page-content_listItem__OiqX8 .order-by-shop-page-content_info__FuDb_ .order-by-shop-page-content_description__araiL .order-by-shop-page-content_dividerDesktop__RbAT9 {
								display: none
						}
}

	@media (min-width: 62em) {

	.order-by-shop-page-content_container____CFz .order-by-shop-page-content_page__T8SYS .order-by-shop-page-content_listBlock__9YuTV .order-by-shop-page-content_listItem__OiqX8 .order-by-shop-page-content_info__FuDb_ .order-by-shop-page-content_description__araiL .order-by-shop-page-content_dividerMobile__l_gFM {
								display: none
						}
}

	.order-by-shop-page-content_container____CFz .order-by-shop-page-content_page__T8SYS .order-by-shop-page-content_listBlock__9YuTV .order-by-shop-page-content_listItem__OiqX8 .order-by-shop-page-content_info__FuDb_ .order-by-shop-page-content_description__araiL .order-by-shop-page-content_status__E_iGJ {
							width: fit-content;
							display: inline-block;
                            margin-right: 12px;
						}

	@media (max-width: 61.99375em) {

	.order-by-shop-page-content_container____CFz .order-by-shop-page-content_page__T8SYS .order-by-shop-page-content_listBlock__9YuTV .order-by-shop-page-content_listItem__OiqX8 .order-by-shop-page-content_info__FuDb_ .order-by-shop-page-content_description__araiL .order-by-shop-page-content_status__E_iGJ {
								display: none
						}
}

	.order-by-shop-page-content_container____CFz .order-by-shop-page-content_page__T8SYS .order-by-shop-page-content_listBlock__9YuTV .order-by-shop-page-content_listItem__OiqX8 .order-by-shop-page-content_info__FuDb_ .order-by-shop-page-content_description__araiL .order-by-shop-page-content_detailsBlock__JnZ8E {
							display: grid;
							grid-template-columns: 1fr auto 1fr;

							gap: 32px;
						}

	@media (max-width: 74.99375em) {

	.order-by-shop-page-content_container____CFz .order-by-shop-page-content_page__T8SYS .order-by-shop-page-content_listBlock__9YuTV .order-by-shop-page-content_listItem__OiqX8 .order-by-shop-page-content_info__FuDb_ .order-by-shop-page-content_description__araiL .order-by-shop-page-content_detailsBlock__JnZ8E {
                                gap: 0;
								grid-template-columns: 1fr;
						}
}

	.order-by-shop-page-content_container____CFz .order-by-shop-page-content_page__T8SYS .order-by-shop-page-content_listBlock__9YuTV .order-by-shop-page-content_listItem__OiqX8 .order-by-shop-page-content_info__FuDb_ .order-by-shop-page-content_description__araiL .order-by-shop-page-content_detailsBlock__JnZ8E .order-by-shop-page-content_qtyPriceBlock__BUV48 {
                                padding: 16px 8px;
								display: flex;
								justify-content: space-between;
							}

	.order-by-shop-page-content_container____CFz .order-by-shop-page-content_page__T8SYS .order-by-shop-page-content_listBlock__9YuTV .order-by-shop-page-content_listItem__OiqX8 .order-by-shop-page-content_info__FuDb_ .order-by-shop-page-content_description__araiL .order-by-shop-page-content_detailsBlock__JnZ8E .order-by-shop-page-content_qtyPriceBlock__BUV48 > div {
									display: flex;
									flex-direction: column;
									align-items: center;
									gap: 6px;
								}

	.order-by-shop-page-content_container____CFz .order-by-shop-page-content_page__T8SYS .order-by-shop-page-content_listBlock__9YuTV .order-by-shop-page-content_listItem__OiqX8 .order-by-shop-page-content_info__FuDb_ .order-by-shop-page-content_description__araiL .order-by-shop-page-content_detailsBlock__JnZ8E .order-by-shop-page-content_qtyPriceBlock__BUV48 > div > span {
									font-size: 14px;
								}

	.order-by-shop-page-content_container____CFz .order-by-shop-page-content_page__T8SYS .order-by-shop-page-content_listBlock__9YuTV .order-by-shop-page-content_listItem__OiqX8 .order-by-shop-page-content_info__FuDb_ .order-by-shop-page-content_description__araiL .order-by-shop-page-content_detailsBlock__JnZ8E .order-by-shop-page-content_qtyPriceBlock__BUV48 > div > span:first-child {
									color: var(--grey-4);
								}

	.order-by-shop-page-content_container____CFz .order-by-shop-page-content_page__T8SYS .order-by-shop-page-content_listBlock__9YuTV .order-by-shop-page-content_listItem__OiqX8 .order-by-shop-page-content_info__FuDb_ .order-by-shop-page-content_description__araiL .order-by-shop-page-content_detailsBlock__JnZ8E .order-by-shop-page-content_qtyPriceBlock__BUV48 > div > span:nth-child(2) {
								}

	.order-by-shop-page-content_container____CFz .order-by-shop-page-content_page__T8SYS .order-by-shop-page-content_listBlock__9YuTV .order-by-shop-page-content_listItem__OiqX8 .order-by-shop-page-content_services__V4UkR {
					display: flex;
					flex-direction: column;
					gap: 8px;
				}

	.order-by-shop-page-content_container____CFz .order-by-shop-page-content_page__T8SYS .order-by-shop-page-content_listBlock__9YuTV .order-by-shop-page-content_listItem__OiqX8 .order-by-shop-page-content_summary__4eNcG {
					display: grid;
					grid-template-columns: 1fr;
					align-items: center;
				}

	@media (max-width: 61.99375em) {

	.order-by-shop-page-content_container____CFz .order-by-shop-page-content_page__T8SYS .order-by-shop-page-content_listBlock__9YuTV .order-by-shop-page-content_listItem__OiqX8 .order-by-shop-page-content_summary__4eNcG {
						grid-template-columns: 1fr;
				}
}

	.order-by-shop-page-content_container____CFz .order-by-shop-page-content_page__T8SYS .order-by-shop-page-content_listBlock__9YuTV .order-by-shop-page-content_listItem__OiqX8 .order-by-shop-page-content_summary__4eNcG .order-by-shop-page-content_itemSummary__iDXyG {
						display: flex;
						flex-direction: column;
						align-items: flex-end;
					}

	.order-by-shop-page-content_container____CFz .order-by-shop-page-content_page__T8SYS .order-by-shop-page-content_listBlock__9YuTV .order-by-shop-page-content_listItem__OiqX8 .order-by-shop-page-content_summary__4eNcG .order-by-shop-page-content_itemSummary__iDXyG > div {
							width: 100%;
							max-width: 320px;
							margin: 0;
							display: grid;
                            grid-template-columns: 5fr 2fr;

                            font-weight: 400;
                            font-size: 14px;
                            line-height: 24px;
                            color: var(--grey-4);
						}

	.order-by-shop-page-content_container____CFz .order-by-shop-page-content_page__T8SYS .order-by-shop-page-content_listBlock__9YuTV .order-by-shop-page-content_listItem__OiqX8 .order-by-shop-page-content_summary__4eNcG .order-by-shop-page-content_itemSummary__iDXyG > div > span:nth-child(2) {
								white-space: nowrap;
                                color: var(--grey-9);
							}

	.order-by-shop-page-content_container____CFz .order-by-shop-page-content_page__T8SYS .order-by-shop-page-content_listBlock__9YuTV .order-by-shop-page-content_listItem__OiqX8 .order-by-shop-page-content_summary__4eNcG .order-by-shop-page-content_itemSummary__iDXyG > div:last-child * {
							font-weight: 600;
                            color: var(--grey-9);

						}

	.order-by-shop-page-content_container____CFz .order-by-shop-page-content_page__T8SYS .order-by-shop-page-content_totalBlock__ClSzA {
			display: grid;
			grid-template-rows: auto auto 1fr;
			gap: 12px;
			margin-top: 40px;
		}

	@media (max-width: 61.99375em) {

	.order-by-shop-page-content_container____CFz .order-by-shop-page-content_page__T8SYS .order-by-shop-page-content_totalBlock__ClSzA {
				margin-top: 0;
		}
}

	.order-by-shop-page-content_container____CFz .order-by-shop-page-content_page__T8SYS .order-by-shop-page-content_totalBlock__ClSzA .order-by-shop-page-content_payBlock__SVnNI {
				border: 1px solid #dfdfdf;
				border-radius: 4px;

				display: grid;
				grid-template-rows: auto auto 1fr;
				gap: 12px;
			}

	.order-by-shop-page-content_container____CFz .order-by-shop-page-content_page__T8SYS .order-by-shop-page-content_totalBlock__ClSzA .order-by-shop-page-content_payBlock__SVnNI .order-by-shop-page-content_toPay__gl_97 {
					padding: 12px 16px 0;
				}

	.order-by-shop-page-content_container____CFz .order-by-shop-page-content_page__T8SYS .order-by-shop-page-content_totalBlock__ClSzA .order-by-shop-page-content_payBlock__SVnNI .order-by-shop-page-content_toPay__gl_97 p {
						margin: 0;
						font-size: 15px;
					}

	.order-by-shop-page-content_container____CFz .order-by-shop-page-content_page__T8SYS .order-by-shop-page-content_totalBlock__ClSzA .order-by-shop-page-content_payBlock__SVnNI .order-by-shop-page-content_toPay__gl_97 strong {
						font-size: 40px;
					}

	.order-by-shop-page-content_container____CFz .order-by-shop-page-content_page__T8SYS .order-by-shop-page-content_totalBlock__ClSzA .order-by-shop-page-content_payBlock__SVnNI .order-by-shop-page-content_descPay__qwA6x {
					padding: 0 16px;
				}

	.order-by-shop-page-content_container____CFz .order-by-shop-page-content_page__T8SYS .order-by-shop-page-content_totalBlock__ClSzA .order-by-shop-page-content_payBlock__SVnNI .order-by-shop-page-content_descPay__qwA6x p {
						margin: 0;
						font-size: 15px;
					}

	.order-by-shop-page-content_container____CFz .order-by-shop-page-content_page__T8SYS .order-by-shop-page-content_totalBlock__ClSzA .order-by-shop-page-content_button__RZLAv {
				height: 48px;
				border-radius: 4px;
				padding: 12px 24px;
				border: none;

				transition: background-color 150ms ease-in-out;
			}

	.order-by-shop-page-content_container____CFz .order-by-shop-page-content_page__T8SYS .order-by-shop-page-content_totalBlock__ClSzA .order-by-shop-page-content_buttonPay__VeH6W {
				background-color: #ec1944;
				color: white;
			}

	.order-by-shop-page-content_container____CFz .order-by-shop-page-content_page__T8SYS .order-by-shop-page-content_totalBlock__ClSzA .order-by-shop-page-content_buttonPay__VeH6W:hover {
					background-color: #d30d36;
				}

	.order-by-shop-page-content_container____CFz .order-by-shop-page-content_page__T8SYS .order-by-shop-page-content_totalBlock__ClSzA .order-by-shop-page-content_buttonPay__VeH6W:active {
					background-color: #b60327;
				}

	.order-by-shop-page-content_container____CFz .order-by-shop-page-content_page__T8SYS .order-by-shop-page-content_totalBlock__ClSzA .order-by-shop-page-content_buttonSave__Pql5c {
				background-color: white;
				color: var(--secondary-blue);
				border: 1px solid var(--secondary-blue);
			}

	.order-by-shop-page-content_container____CFz .order-by-shop-page-content_page__T8SYS .order-by-shop-page-content_totalBlock__ClSzA .order-by-shop-page-content_buttonSave__Pql5c:hover {
					border-color: var(--secondary-blue);
					background-color: var(--secondary-blue);
					color: #ffffff;
				}

	.order-by-shop-page-content_container____CFz .order-by-shop-page-content_page__T8SYS .order-by-shop-page-content_totalBlock__ClSzA .order-by-shop-page-content_buttonSave__Pql5c:active {
					border-color: var(--secondary-blue);
					background-color: var(--secondary-blue);
					color: #ffffff;
				}

	.order-by-shop-page-content_container____CFz .order-by-shop-page-content_page__T8SYS .order-by-shop-page-content_totalBlock__ClSzA .order-by-shop-page-content_info__FuDb_ {
				display: flex;
				gap: 6px;
			}

	.order-by-shop-page-content_container____CFz .order-by-shop-page-content_page__T8SYS .order-by-shop-page-content_totalBlock__ClSzA .order-by-shop-page-content_info__FuDb_ > img {
					width: 16px;
					height: 16px;
				}

	.order-by-shop-page-content_container____CFz .order-by-shop-page-content_page__T8SYS .order-by-shop-page-content_totalBlock__ClSzA .order-by-shop-page-content_info__FuDb_ > span {
					font-size: 12px;
					color: var(--secondary-blue);
				}

.order-by-shop-page-content_additional__9DHXV {
	position: absolute;
}

